.fill-btn-green {
    transition: .5s all ease;
}
.fill-btn-purple {
    transition: .5s all ease;
}
.fill-btn-red {
    transition: .5s all ease;
}
.fill-btn-green:hover {
    box-shadow: 0px 5px 10px 0px rgba(49, 196, 141, 0.45) !important;
    background-color: rgb(21, 177, 93);
}
.fill-btn-purple:hover {
    box-shadow: 0px 5px 10px 0px rgba(85, 33, 181, 0.45) !important;
    background-color: rgb(85, 33, 181);
}
.fill-btn-red:hover {
    box-shadow: 0px 5px 10px 0px rgba(224, 36, 36, 0.45) !important;
    background-color: rgb(224, 36, 36);
}
.purple-red {
    background-image: linear-gradient(160deg, #5521b5 50%, #e02424 0);
}

.purple-green {
    background-image: linear-gradient(160deg, #5521b5 50%, #16B15D 0);
}

input[type="range"] {
    --range-progress: 0;
    
    -webkit-appearance: none;
    position: relative;
    background:#ccc;
    width: 100%;
    height: 3px;
    border-radius: 3px;
    cursor: pointer;
  }
  
  /* Input range - firefox */
  input[type="range"]::-moz-range-track {
    position: relative;
    background:#ccc;
    width: 100%;
    height: 3px;
    border-radius: 3px;
    cursor: pointer;
  }
  
  /* played progress length - Chrome & safari*/
  input[type="range"]::before {
    content: '';
    height: 3px;
    background: #faca15;
    width: var(--range-progress);
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  /* played progress length - firefox */
  input[type="range"]::-moz-range-progress {
    background: #faca15;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    height: 3px;
  }
  
  /* slider thumb - chrome and safari */
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border-radius: 50%;
    border: none;
    background-color: #faca15;
    cursor: pointer;
    visibility: hidden;
    position: relative;
  }
  
  /* dragging thumb - chrome and safari */
  input[type="range"]:active::-webkit-slider-thumb {
    transform: scale(1.2);
    visibility: visible;
  }
  
  input[type="range"]:hover::-webkit-slider-thumb {
    transform: scale(1.2);
    visibility: visible;
  }
  
  /* slider thumb - firefox */
  input[type="range"]::-moz-range-thumb {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: #0060D9;
    cursor: pointer;
    border: transparent;
    position: relative;
  }
  /* dragging thumb - firefox */
  input[type="range"]:active::-moz-range-thumb {
    transform: scale(1.2);
  }
  
  